
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #008ea7;
    color: white;
    z-index: 100;
  }




  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .header-content h1 {
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
  }
  
  .nav {
    display: flex;
    gap: 1rem;
  }

  .nav a {
    color: white;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
  }
  
  .nav.open {
    display: block;
  }
  
  .hamburger {
    display: none;
    background: none;
    border: none;
    padding: 0;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
  }

  .logo-font {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    gap: 1rem;
  }
  .logo-font h1 {
    font-family: "Anta", serif;
    font-size: 1.6rem;
    letter-spacing: 2px;
    margin: 0;
  }

  .logo-font:active {
    color: #fff;
  }

  .logo-font:hover {
    color: #fff;
  }

  .logo-font:visited {
    color: #fff;
  }

  .logo-icon {
    width: 35px;
    height: 35px;
  }
  
  @media (max-width: 768px) {
    .nav {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 10px;

      padding: 1rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    .nav.open {
      display: flex;
    }
  
    .hamburger {
      display: block;
    }
  }
  