:root {
  --bs-heading-color: #fff!important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  background-color: #fefffb;
  color: black;
  height: 100dvh;
  display: flex;
}


/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0f3036;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #008ea7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #02acca;
}

p {
  margin-block-end: 0.3rem;
  margin-block-start: 0.3rem;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  line-height: 130%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

nav {
  display: flex;
    color: white;
    justify-content: flex-end;
    width: -webkit-fill-available;
    padding: 0.5rem;
    align-items: center;
    background-color: #008ea7;
}

nav a {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

nav a:hover {
  text-decoration: underline;
}

.log-out-btn {
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.container {

  margin: auto;
  width: 100%;
  margin: auto;
    min-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    position: relative;
}

/* Buttons */

.blue-btn {
  background-color: #01505d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  margin: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
}

.blue-btn:hover {
  background-color: #005c6a;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.cancel-btn {
  background-color: #ab3a17;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  margin: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

.cancel-btn:hover {
  background-color: #ba3c15;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.save-btn {
  background-color: #005f45;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  margin: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

.save-btn:hover {
  background-color: #007254;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.img-btn {
  background-color: unset;
  color: white;
  border: none;
  
  padding: 0;
  margin: 0.5rem;
  cursor: pointer;
}

.img-btn img {
  width: 25px;
  height: 25px;
}


/* CSS for the modal overlay */
.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #E3E2DF;
  z-index: 999;
  justify-content: center;
  align-items: center;
}

.modal-content, .new-workout-form {
 
  max-width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 50%;
    
}

.modal-content input[type=text], .modal-content input[type=number], .modal-content input[type=date], .modal-content input[type=time], .modal-content select, .new-workout-form input[type=text], .new-workout-form input[type=number], .new-workout-form input[type=date], .new-workout-form input[type=time], .new-workout-form select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #f5f5f5;
    border: none;
}

.open {
  display: flex;
}

.modal-new-set, .modal-new-set-btns {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  align-items: flex-end;
  flex-wrap: wrap;
}

.modal-new-set *, .modal-new-set-btns * {
  flex: 1;
  min-width: 100px;
}

.modal-new-set input[type=number], .modal-new-set input[type=date], .modal-new-set input[type=time], button {
  
  padding: 12px 20px;
  box-sizing: border-box;
  
}

.modal-new-set-btns {
  justify-content: flex-end;
}

.modal-new-set-btns button {
  min-width: 20%;
  min-height: 50px;
    
}

.modal-new-set button {
  min-width: 10%;
}

.modal-new-set-btns button {
  margin: 2rem 0.5rem;
}

/* Lists */

.list-container, .list-container-workouts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

    
}
.list-container-workouts {
  width: 50%;
}
.list-row-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  color: black;
  

}

.list-row-parent {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 10px;
  margin: 1rem 1rem;
  
}

.list-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #e6f0f1;
  border-radius: 2px;
  margin: 0.2rem 0rem;
 
}




.list-row-workouts button {
  height: 50px;
    margin: auto 0.5rem;
    flex: 1;
}

.list-row-icon {
    margin: 0;
    border-radius: 10px 0px 0px 10px;
    width: 70px;
    height: 100%;
    display: flex;

}

.list-row-icon img {
  width: 40px;
  height: 40px;
  margin: auto;
  margin-left: 1rem;
}

.list-row-content {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.list-row-body {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.list-row-btns {
  flex: 1;
}

.list-row-set-btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;

}

.list-row-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.3rem;
  flex: 5;
  align-items: center;
  margin-left: 0.5rem;
}

.set-template-one {
  font-size: 0.9rem;
  font-weight: 500;
}


.list-row-details-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;

}

.list-row-header {
  display: flex;
  border-radius: 9px 9px 0px 0px;
  align-items: center;
  background-color: #008ea7;
}

.list-row-header h3 {
  color: white;
}

.list-row-type-title {
  margin-left: 0.5rem;
}

.list-row-workouts {
  display: flex;

  width: 100%;
  border-bottom: 2px solid #ffffff17;
    line-height: 2rem;
    background-color: #e6eeef; 
    margin-top: 0.3rem;
  }

.list-row-workouts a {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;;
}
.list-row-workouts a, .list-row-workouts a:visited {
  color: #0a0a0a;
  text-decoration: none;
}

.list-row-workouts a > p {
  margin: 0.5rem;
  width: 30%;
}

.list-row-workouts a > p:first-child {
  width: 100px;
}

.list-row-workouts a > img {
  width: 50px;
  background-color: #008EA7;
}

.new-set-inline-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  justify-content: space-between;
}

.new-set-inline-form-section {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0.3rem;
}

.new-set-inline-form-section label {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.new-set-inline-form input[type=number], .new-set-inline-form input[type=date], .new-set-inline-form input[type=time] {
  width: 80px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 8px;
    border: none;
}



.suggestions, .equipment-suggestions {
  position: absolute;

    max-height: 181px;
    overflow-y: auto;
    z-index: 1;
    width: 100%;
    border-radius: 8px;
    background-color: #f5f5f5;
    border: none;
    color: black;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.suggestion-container, .equipment-suggestion-container {
  position: relative;

}

.suggestion-item {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.suggestion-item:hover {
  background-color: #d5d5d5; /* Add hover effect */
}



.dashboard-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}


.wrapper {
  width: 150px;
    /* height: 150px; */
    background-color: #008ea7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
  
}

.wrapper:hover {
  background-color: #008ea7;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.banner-image img {
  margin: auto;
  height: 70px;
  width: 70px;
}

.banner-image h3, .dashboard-container a {
  color: white;
}

.dashboard-container a {
  text-decoration: none;
}

h1{
  font-family: 'Righteous', sans-serif;
  text-transform: uppercase;
  font-size: 2rem;
}

h3 {
  font-family: 'lato', sans-serif;
}


/* New workout form */
.new-workout-form {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.auth-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.auth-container h2 {
  margin-bottom: 20px;
  text-align: center;
}

.auth-container label {
  display: block;
  margin-bottom: 8px;
}

.auth-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.auth-container .btn-primary {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.auth-container .btn-primary:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.success-message {
  color: green;
  margin-bottom: 10px;
}


/* set templates */



.set-template-item {
  margin: 0 0.5rem;
  min-width: 60px;
  text-align: center;
}

.set-template-item-x {
  margin: 0rem;
  text-align: center;

}

/* Max width 500px */
@media screen and (max-width: 500px) {
  #root {
    margin: 0;
  }

  .container {
    width: 100%;
    height: 100%;
  }

  .list-container, .list-container-workouts {
    width: 100%;
    margin: 1rem auto;
  }
  .modal-container {
    width: 100%;
  }
  /* New workout form */
.new-workout-form {
  
  width: 90%;
}
.list-row-details {
  margin-left: 0rem;
}
::-webkit-scrollbar {
  width: 5px;
}

}

@media screen and (max-width: 380px) {
  .list-row-body {
    margin: 0.5rem;
  }

  .list-row-parent {
    margin: 1rem 0.5rem;
  }

}