.exercise-item img {
    background-color: #008EA7;
    width: 27px;
}

.exercise-item {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    background-color: #e4fbff;
    
    margin: 0.5rem;
    cursor: pointer;
    transition: 0.3s;
    align-items: center;
}

.exercise-item label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: #333;
    font-weight: bold;
    text-transform: capitalize;
    cursor: pointer;
    line-height: 20px;
}

.exercise-item input[type="checkbox"] {
    display: none; /* Hides the native checkbox */
}

.exercise-item input[type="checkbox"] + label {
    position: relative;
    padding-left: 30px; /* Space for the custom checkbox */
    cursor: pointer;
    line-height: 20px;
}

.exercise-item input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 25px; /* Custom checkbox size */
    height: 25px;
    border: 1px solid #ccc; /* Matches input styles */
    border-radius: 4px; /* Rounded corners */
    background-color: #fff; /* Background color */
    box-sizing: border-box; /* Prevent padding/border issues */
    cursor: pointer;
}

.exercise-item input[type="checkbox"]:checked + label::before {
    background-color: #007bff; /* Checked background color */
    border-color: #007bff; /* Checked border color */
    display: flex;
    justify-content: center;
    align-items: center;
}

.exercise-item input[type="checkbox"]:checked + label::after {
    content: "✔"; /* Checkmark */
    position: absolute;
    top: 3px;
    left: 3px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
}


.exercise-item input,
.exercise-item select,
.exercise-item textarea,
.container input,
.container select,
.container textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}