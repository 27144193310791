/* ErrorModal.css */
.error-modal-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .error-modal {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 300px;
  }
  
  .error-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .error-modal-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .error-modal-close {
    cursor: pointer;
    font-size: 20px;
    color: #888;
  }
  
  .error-modal-content {
    margin-top: 10px;
  }
  